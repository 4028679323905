import clsx from "clsx";
import { useGlobalNavContext } from "./GlobalNavContext";

export type NavLinkProps<T> = React.PropsWithChildren<{
  href: string;
  className?: string;
  onClick?: () => void;
}>;

export function Link<T>(props: NavLinkProps<T>) {
  const { LinkComponent } = useGlobalNavContext();
  // if no custom link component passed to Global Nav, use standar <a> link
  if (!LinkComponent) return <a {...props}>{props.children}</a>;
  return <LinkComponent {...props}>{props.children}</LinkComponent>;
}

export function NavLink<T>({
  href,
  className,
  children,
  onClick,
  ...props
}: NavLinkProps<T>) {
  return (
    <Link
      className={clsx(
        className,
        "font-roobert font-medium text-white betterhover:hover:text-green-500 text-lg",
      )}
      data-testid={"nav-link-" + children?.toString().toLowerCase()}
      onClick={onClick}
      href={href}
      {...props}
    >
      {children}
    </Link>
  );
}
