import { createContext, useContext } from "react";
import { GlobalNavProps } from "../types";
import { noop } from "@react-hookz/web/cjs/util/const";

export type GlobalNavContextType = Omit<GlobalNavProps, "className">;

const GlobalNavContext = createContext<GlobalNavContextType>({
  headers: [],
  dropdownMenus: [],
  isSignedIn: false,
  onSignInClick: noop,
  profileMenu: { swooshHandle: "", userImage: "", links: [] },
  pageClickedEvent: noop,
  LinkComponent: undefined,
});

GlobalNavContext.displayName = "GlobalNavContext";

export const GlobalNavProvider = (
  props: React.PropsWithChildren<GlobalNavContextType>,
) => {
  return (
    <GlobalNavContext.Provider
      value={{
        ...props,
      }}
    >
      {props.children}
    </GlobalNavContext.Provider>
  );
};

export const useGlobalNavContext = () => {
  return useContext(GlobalNavContext);
};
