import { PropsWithChildren } from "react";
import { Menu } from "@headlessui/react";
import clsx from "clsx";
export const DESKTOP_NAV_DROPDOWN_ID = "dropdown-menu-popover";

const MAP_ALIGNMENT = {
  left: "left-0",
  right: "right-0",
};

type Props = {
  dropdownTitle: string;
  className?: string;
  align: "left" | "right";
} & PropsWithChildren;

const DropdownMenuItems: React.FC<Props> = ({
  className,
  dropdownTitle,
  children,
  align,
}) => {
  return (
    <Menu.Items
      data-testid={DESKTOP_NAV_DROPDOWN_ID + `-${dropdownTitle}`}
      as="ul"
      className={clsx(
        "absolute z-10 w-auto pt-1.5",
        "focus:outline-none",
        className,
        MAP_ALIGNMENT[align],
      )}
    >
      <div className="bg-gray-950 shadow-[0px_2px_6px_#11111120] flex flex-col shadow-md py-2.5 text-sm text-white pt-[18px] pb-6 px-4 rounded-md min-w-56 max-w-[300px] text-nowrap">
        {children}
      </div>
    </Menu.Items>
  );
};

export default DropdownMenuItems;
